import React from "react";

import "../css/musicianInfo.css";

const MusicianInfo = (props) => {
    return (
      <div className="musicianInfoContainer">
        <h1 className="title">{props.musician.first_name}{" "}{props.musician.last_name}</h1>
        <h2 className="memberTitle">Grupas:</h2>
        {props.musician.bands.map((input) => (
          <div className="memberText" key={input.id}>
            <div>{input.name}</div>           
          </div>
        ))}
      </div>
    );

}


export default MusicianInfo