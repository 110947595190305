import React, {useState, useEffect} from "react";
import {Autocomplete, TextField} from "@mui/material";
import {mainTheme} from "../constants/visualThemes";
import "../css/searchBar.css";

const SearchBar = (props) => {
    const [searchTerm, setSearchTerm] = useState(""); // Track the search term
    const [mergedResults, setMergedResults] = useState([]); // Store combined search results
    const [selectedItem, setSelectedItem] = useState(null);

    //Sync selectedItem state with props changes
    useEffect(() => {
      if (props.selectedMusician !== null || props.selectedBand !== null) {
        setSelectedItem(createSelectedItemFromProps); // Set to default value
      }
  }, [props.selectedMusician, props.selectedBand]);

    // Create an item for a musician or band from the props
    const createSelectedItemFromProps = () => {
        if (props.selectedMusician) {
            return createMusicianItem(props.selectedMusician);
        } else if (props.selectedBand) {
            return createBandItem(props.selectedBand);
        }
        return null;
    };

    const createMusicianItem = (musician) => ({
        type: "Musician",
        label: `${musician.first_name} ${musician.last_name}`,
        id: musician.id,
        bands: musician.bands
    });

    const createBandItem = (band) => ({
        type: "Band",
        label: band.name,
        id: band.id
    });

    // Handle the search input change
    const handleSearchChange = (event, newValue) => {
        if (!event) {
                    return;
                }   

        setSearchTerm(event.target.value);

        if (!event.target.value) {
            // Clear selection if search term is empty
            setMergedResults([]);
            setSelectedItem(null); // Set value to null if no search term
            return;
        }

        // Search for musicians by concatenating first_name and last_name
        const foundMusicians = props.musicians.filter((musician) =>
            `${musician.first_name} ${musician.last_name}`
                .toLowerCase()
                .includes(event.target.value.toLowerCase())
        );

        // Search for bands
        const foundBands = props.bands.filter((band) =>
            band.name.toLowerCase().includes(event.target.value.toLowerCase())
        );

        // Merge results and mark them as either "Musician" or "Band"
        const merged = [
            ...foundMusicians.map((musician) => (
                createMusicianItem(musician)
            )),
            ...foundBands.map((band) => (
                createBandItem(band)
            ))
        ];

        // Update the merged results for display in the Autocomplete dropdown
        setMergedResults(merged);
    };

    function isArrayOfDicts(arr) {
        return Array.isArray(arr) && arr.every(item => item && typeof item === 'object' && !Array.isArray(item));
    }

    // Handle selection from the Autocomplete dropdown
    const handleSelect = (event, newValue) => {
        if (newValue) {
            setSelectedItem(newValue);
            if (newValue.type === "Band") {
                // If a band is selected, update the selected band
                const band = props.bands.find((band) => band.id === newValue.id);
                if (band) {
                    props.updateSelectedBand(band);
                }
            } else if (newValue.type === "Musician") {
                // If a musician is selected, select the first band they played in
                console.log('new value: ', newValue);
                const musician = props.musicians.find((musician) => musician.id === newValue.id);
                // todo fix this to get all the band info in the very beginning, when fetching the musician info 
                // but right now check if the newValue.bands is maybe a list of dicts, and in that case transform it to a list of ids
                if (musician) {
                    if (isArrayOfDicts(newValue.bands)) {
                        musician.bands = newValue.bands.map(band => band.id);
                    }
                }

                props.updateSelectedMusician(musician); // Update selected musician
            }
        }
        else {
          // User cleared the selection
          if (selectedItem.type === "Band") {
              props.updateSelectedBand(null); // Reset selectedBand to null
          }
          else {
          setSelectedItem(null);
          props.updateSelectedMusician(null); // Reset selectedMusician to null
          }
      }
    };

    return (
        <div className="bandSearchField">
            <Autocomplete
                sx={{
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: mainTheme.palette.primary.darker,
                        backgroundColor: mainTheme.palette.primary.darker,
                        opacity: 0.1,
                    },
                    "& label": {
                        "&.Mui-focused": {
                            color: mainTheme.palette.primary.main,
                        },
                    },
                }}
                disablePortal
                options={mergedResults} // Use the merged results (both musicians and bands)
                getOptionLabel={(option) => option.label} // Show the label (full name for musicians, band name for bands)
                renderOption={(props, option) => (
                    <li {...props} key={`${option.type}-${option.id}`}> {/* Ensure unique key */}
                        {option.label} <small>&nbsp;({option.type == 'Musician' ? 'Mūziķis': 'Grupa'})</small>
                    </li>
                )}
                value= {selectedItem}
                renderInput={(params) => (
                    <TextField {...params} id="searchField" label="Meklēt" hiddenLabel={false}/>
                )}
                onInputChange={handleSearchChange} // Call when the user types in the input field
                onChange={handleSelect} // Handle item selection
            />
        </div>
    );
};

export default SearchBar;
