import { createTheme } from "@mui/material/styles";


export const mainTheme = createTheme({
  palette: {
    primary: {
      main: "#CEC8C8",
      darker: "#34363A",
      selected: "#f47336",
      labelStroke: "#808080",
      labelFill: "#808080",
      link:"#999",
      linkSelected: "white",
    },
    secondary: {
      main: "#5d6169",
    },
    neutral: {
      main: "#64748B",
      contrastText: "#fff",
    },
    zibensPaDibenu:{
      main:"#31BDEA"
    },
    austra: {
      main:"#ffffff"
    },
    trimda:{
      main :"#ee61c0"
    },
    kartupelpalma:{
      main:"#dd93ab"
    },
    tornis:{
      main: "#ff4d4d"
    },
    adminu_4:{
      main: "#4077ff"
    },
    jauna_scena:{
      main: "#fce900"
    },
    hadrons:{
      main: "#FFA500"
    },
    lv_hip_hop:{
      main: "#028A0F"
    },
    smiltenesValmieras:{
      main: "#ffa733"
    },
    jelgavas_scena:{
      main: "#fffea2"
    },
    turaidas_2a:{
      main: "#9e62f4"
    },
    liepajroks:{
      main: "#5be800"
    },
    tallinn_music_week:{
      main: "#5a79b1"
    },
    muzikala_banka:{
      main: "#bd80c3"
    },
    metala_balva:{
      main: "#bdd9ea"
    },
    metala_scena:{
      main:"#3c7c70"
    },
    folkloras_kopa:{
      main:"#5effea"
    },
    barona_buduars:{
      main:"#1bc1fe"
    },
    nodes: {
      main: "#808080",
      selected: "#808080",
      musician: "#b5aeae" //"#f9246f",
    }
  },
});